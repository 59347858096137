<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Lista de Precios</h4>
            <div class="small text-muted">Administración de las distintas listas de precios.</div>
          </b-col>
          
          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Lista de Precios" v-if="!isSeller">
              <i class="fa fa-plus"></i> Agregar
            </b-button>

            <b-col sm="12" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>
        </b-row>
      </b-card>
      
      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="10">
            <b-row>
              <b-col sm="4" md="3">
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="text" size="sm" placeholder="Ingrese un nombre" v-model="filters.name" v-on:keyup.enter.native="load"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col sm="4" md="3">
                <b-button variant="outline-dark" @click="load()" size="sm" class="mt-1">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table class="mb-0"
                     responsive="sm"
                     head-variant="dark"
                     :hover="true"
                     :small="true"
                     :fixed="false"
                     :items="table.items"
                     :fields="table.fields"
                     :filter="table.filter"
                     :current-page="table.currentPage"
                     :per-page="table.perPage"
                     v-if="table.items.length">  

                <template v-slot:cell(id)="data">
                  <strong>{{data.item.id}}</strong>
                </template>

                <template v-slot:cell(name)="data">                  
                  {{data.item.name}}
                </template>

                <template v-slot:cell(reference)="data">                  
                  <strong>{{data.item.reference}}</strong>                                      
                </template>

                <template v-slot:cell(currency_id)="data">
                  {{data.item.currency.name}}
                </template>

                <template v-slot:cell(utility)="data">
                  {{Intl.NumberFormat('es-AR',{style:'percent'}).format( getUtility(data.item) )}}                                              
                </template>                
                
                <template v-slot:cell(public)="data">
                  <div v-if="data.item.public">
                    <span class="price-list-column-public-yes">SI</span>
                  </div>
                  <div v-else>
                    <span  class="price-list-column-public-no">NO</span>
                  </div>
                </template>

                <template v-slot:cell(active)="data">
                  <div v-if="data.item.active">
                    <b-badge variant="success">Activo</b-badge>
                  </div>
                  <div v-else>
                    <b-badge variant="danger">Inactivo</b-badge>
                  </div>
                </template> 

                <template v-slot:cell(file1)="data">                                                            
                  <b-link :href="data.item.file1" v-if="data.item.file1" target="_blank">
                    <b>Ver Archivo</b>
                  </b-link>                                 
                </template>  

                <template v-slot:cell(file2)="data">                                                            
                  <b-link :href="data.item.file2" v-if="data.item.file2" target="_blank">
                    <b>Ver Archivo</b>
                  </b-link>                                  
                </template>                                        

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right" v-if="!isSeller">
                    <b-dropdown-header>Acciones</b-dropdown-header>                                        
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav v-if="table.tablePaginate">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="table.tablePaginate.total"
                                :per-page="table.tablePaginate.per_page"
                                v-model="table.currentPage"
                                @input="load()" />
            </nav>
          </b-col>          
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->      
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"              
              header-text-variant="white"
              size="lg">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-tabs card>
          <b-tab no-body title="General">
            <b-row>
              <b-col md="12">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.name"
                                required
                                placeholder="Ingresar un nombre">
                  </b-form-input>
                </b-form-group>
              </b-col>         

              <b-col md="12">
                <b-form-group label="Referencia">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.reference"
                                required
                                maxlength="5"
                                placeholder="Ingresar un referencia">
                  </b-form-input>
                </b-form-group>
              </b-col>    

              <b-col md="12">
                <FindObject render="search"
                            type="currency" 
                            @select-object="loadCurrency($event)" 
                            :valueID="crud.form.currency_id"/>
              </b-col>                           
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-checkbox v-model="crud.form.public" 
                                  switch 
                                  size="sm" 
                                  class="mt-4 pull-right">
                  Publica
                </b-form-checkbox>            
              </b-col>           
              <b-col md="6">
                <b-form-checkbox v-model="crud.form.active" 
                                  switch 
                                  size="sm" 
                                  class="mt-4 pull-left">
                  Activa
                </b-form-checkbox>            
              </b-col>           
            </b-row>
          </b-tab>
          <b-tab no-body title="Utilidad">
            <b-row>
              <b-col md="6">
                <b-form-group label="Utilidad" description="Utilidad sobre el Precio de Compra.">
                  <b-input-group size="sm" append="%">
                    <b-form-input type="number"                                                                      
                                  v-model="crud.form.utility">
                    </b-form-input>    
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab no-body title="Adjuntos">
            <b-row>
              <b-col md="6">
                <b-form-group label="Adjunto 1">                
                  <b-form-file v-if="!crud.form.fileLink_1" 
                                v-model="crud.form.file_1"                             
                                size="sm"
                                :state="Boolean(crud.form.file_1)"
                                placeholder="Elija un archivo o sueltelo aquí..."
                                drop-placeholder="Suelta el archivo aquí...">
                  </b-form-file>      
                  <div v-else>
                    <b-link :href="crud.form.fileLink_1" target="_blank">
                      <b>Ver Archivo</b>
                    </b-link>           
                    <b-button type="button" variant="outline-dark" size="sm" class="btn-pill ml-2" @click="crud.form.fileLink_1=''" v-b-tooltip.hover title="Cargar otro archivo">
                      <i class="fa fa-refresh"></i>
                    </b-button>                                         
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Referencia Adjunto 1">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.reference_file1"
                                placeholder="Ingresar un referencia">
                  </b-form-input>
                </b-form-group>                
              </b-col>      
              <b-col md="6">
                <b-form-group label="Adjunto 2">                
                  <b-form-file v-if="!crud.form.fileLink_2" 
                                v-model="crud.form.file_2"                             
                                size="sm"
                                :state="Boolean(crud.form.file_2)"
                                placeholder="Elija un archivo o sueltelo aquí..."
                                drop-placeholder="Suelta el archivo aquí...">
                  </b-form-file>      
                  <div v-else>
                    <b-link :href="crud.form.fileLink_2" target="_blank">
                      <b>Ver Archivo</b>
                    </b-link>           
                    <b-button type="button" variant="outline-dark" size="sm" class="btn-pill ml-2" @click="crud.form.fileLink_2=''" v-b-tooltip.hover title="Cargar otro archivo">
                      <i class="fa fa-refresh"></i>
                    </b-button>                                         
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Referencia Adjunto 2">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.reference_file2"
                                placeholder="Ingresar un referencia">
                  </b-form-input>
                </b-form-group>                
              </b-col>                       
            </b-row>   
          </b-tab>
        </b-tabs>
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'

  export default {
    components: {
      FindObject
    },    
    data: () => {
      return {      
        access: {
          module_id: Modules.PRECIOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudPricesLists',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class: 'align-middle'},
            {key: 'name', label: 'Nombre', class: 'align-middle'},            
            {key: 'reference', label: 'Referencia', class: 'align-middle'},                        
            {key: 'currency_id', label: 'Moneda', class: 'align-middle'},            
            {key: 'utility', label: 'Utilidad', class: 'align-middle text-right'},            
            {key: 'public', label: 'Pública', class: 'text-center align-middle'},
            {key: 'active', label: 'Activa', class: 'text-center align-middle'},                      
            {key: 'file1', label: 'Adjunto 1', class: 'text-center align-middle'},                      
            {key: 'file2', label: 'Adjunto 2', class: 'text-center align-middle'},                      
            {key: 'f_action', label:'', class: 'align-middle'},            
          ],
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          tablePaginate: null,  
        },
        crud: {
          form: {
            id: 0,
            reference: '',
            name: '',            
            currency: null,
            currency_id: 0,
            public: false,
            active: false,
            fileLink_1: '',
            file_1: null,  
            fileLink_2: '',
            file_2: null,               
            utility: 0,        
            reference_file1: '',
            reference_file2: '',
          },
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
        },     
        arr: {
          priceList: [],          
        },
        filters: {          
          name: '', 
        },
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {      
      this.load()
    }, 
    computed: {
      isSeller() {
        var seller = Helper.getSeller()
        if(seller) {
          return true
        } else {
          return false
        }
      },
    },      
    methods: {
      getUtility(item) {
        return parseFloat(item.utility) / 100
      },
      getRowCount (items) {
        return items.length
      },
      openDetail(index) {
        this.load()
      },                
      load() {
        let loader = this.$loading.show();
        var result = serviceAPI.filtrarLista(this.filters, this.table.currentPage)

        result.then((response) => {
          var data = response.data
          this.table.items = data.data
          this.table.tablePaginate = data
       
          loader.hide()            
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });        
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.reference = ''
        this.crud.form.name = '' 
        this.crud.form.currency = null
        this.crud.form.currency_id = 0
        this.crud.form.public = false
        this.crud.form.active = false
        this.crud.form.file_1 = null
        this.crud.form.fileLink_1 = ''
        this.crud.form.file_2 = null
        this.crud.form.fileLink_2 = ''   
        this.crud.form.utility = 0             
        this.crud.form.reference_file1 = ''
        this.crud.form.reference_file2 = ''

        this.modal.form.title = "Nueva Lista de Precios"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.reference = item.reference
        this.crud.form.name = item.name      
        this.crud.form.currency = item.currency
        this.crud.form.currency_id = item.currency_id
        this.crud.form.public = item.public
        this.crud.form.active = item.active
        this.crud.form.file_1 = null
        this.crud.form.fileLink_1 = item.file1
        this.crud.form.file_2 = null
        this.crud.form.fileLink_2 = item.file2                
        this.crud.form.utility = item.utility
        this.crud.form.reference_file1 = (item.reference_file1!=null) ? item.reference_file1 : ''
        this.crud.form.reference_file2 = (item.reference_file2!=null) ? item.reference_file2 : ''

        this.modal.form.title = "Editar Lista de Precios"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Lista de Precios',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarLista(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        if(this.crud.form.reference.length>5) {
          this.$awn.alert(Error.showError("La referencia debe tener como máximo 5 caracteres"));
          return false
        }

        let loader = this.$loading.show();

        var file_1 = new FormData();
        if(this.crud.form.fileLink_1) {
          file_1.append("file_1", this.crud.form.fileLink_1);
        } else {        
          if(this.crud.form.file_1) {          
            file_1.append("file_1", this.crud.form.file_1);
          }
        }  
        
        var file_2 = new FormData();
        if(this.crud.form.fileLink_2) {
          file_2.append("file_2", this.crud.form.fileLink_2);
        } else {        
          if(this.crud.form.file_2) {          
            file_2.append("file_2", this.crud.form.file_2);
          }
        }          

        if (this.crud.form.id) {
          var result = serviceAPI.editarLista(JSON.stringify(this.crud.form), file_1, file_2);
        } else {
          var result = serviceAPI.agregarLista(JSON.stringify(this.crud.form), file_1, file_2);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      loadCurrency (object) {
        if(object){
          this.crud.form.currency = object
          this.crud.form.currency_id = object.id
        } else {
          this.crud.form.currency = null
          this.crud.form.currency_id = 0
        }
      },
    }    
  }
</script>
<style>
  .price-list-column-public-no {
    font-weight: bold;
    color: darkred;
  }
  .price-list-column-public-yes {
    font-weight: bold;
    color: darkgreen;
  }
</style>